import React, { useState,useEffect } from "react";
import { FiUpload } from "react-icons/fi";
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux";
import axios from 'axios'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Base_URL } from "../../apiConfig";
import Select from "react-select";
import { MdEdit } from "react-icons/md";

const PartnerPersonalDetails = () => {
  const [isPrefilled, setIsPrefilled] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [profileDetails, setProfileDetails] = useState({
    city_id: null,
    address: "",
    adhar_number: "",
    // adhar_card: null,
    qualification: "",
  });

  const [bankDetails, setBankDetails] = useState({
    account_holder_name: "",
    bank_name: "",
    account_number: "",
    mobilenumber: "",
    ifsc_code: "",
    branch_city: null,
    upi: "",
  });
  const [editableFields, setEditableFields] = useState({
    address: false, // Set to false if the form is prefilled
  qualification: false,
  adhar_number: false,
  city: false,
  // bank
  account_holder_name:false,
  account_number:false,
  upi:false,
  mobilenumber:false,
  branch_city:false,
  bank_name:false,
  ifsc_code:false

  });
  const[bankId,setBankId]=useState(null)
  const[profileId,setProfileId]=useState(null)
  const[cityName,setCityName]=useState('')
  const[branchcityName,setBranchcityName]=useState('')
  const[pictureName,setPictureName]=useState(null)
  const[picture,setPicture]=useState(null)
  const [fileName, setFileName] = useState(""); // File name state
  const [cities, setCities] = useState([]);
  const [citiesBank, setCitiesBank] = useState([]);
  const [isFetched, setIsFetched] = useState(false);
  const[loading,setLoading]=useState(false)


  const { registration_id, token, name } = useSelector((state) => state.auth);

  // Fetch existing details when the component loads
  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const response = await axios.get(`${Base_URL}/certified-partner`, {
          headers: {
            Authorization: `Bearer ${token}`, // Sending token in headers
          },
        });
        const profileData = response.data[0]; // Extract the first object from the array

        if (profileData) {
          setProfileDetails({
            city_id: profileData.city_id || "",
            address: profileData.address || "",
            adhar_number: profileData.adhar_number || "",
            qualification: profileData.qualification || "",
          });
          setCityName(profileData.city_name || "")
          setProfileId(profileData.id || null )
          setPictureName(profileData.file_name || null)
  
          const bankData = profileData.bank_details[0] || {}; // Handle if bank_details is empty
          setBankDetails({
            account_holder_name: bankData.account_holder_name || "",
            bank_name: bankData.bank_name || "",
            account_number: bankData.account_number || "",
            mobilenumber: bankData.mobilenumber || "",
            ifsc_code: bankData.ifsc_code || "",
            branch_city: bankData.branch_city || null,
            upi: bankData.upi || "",
          });
          setBankId(bankData.id || null)
          setBranchcityName(bankData.branch_city_name)

           // Check if profile data is fully pre-filled
           if (
            profileData.city_id &&
            profileData.address &&
            profileData.adhar_number &&
            profileData.qualification
          ) {
            setIsPrefilled(true);
          }
          // Check if bank details are prefilled
if (
  (bankData.account_number || bankData.upi) // Either account number or UPI is filled
) {
  setIsFetched(true);
}
        }
      } catch (error) {
        console.error("Error fetching details:", error);
      }
    };

     // Fetch city data on component mount
    const fetchCity = async () => {
      try {
        const response = await axios.get(`${Base_URL}/get/city`);
        const cityOptions = response.data.map((city) => ({
          value: city.id,
          label: city.name,
        }));
        setCities(cityOptions);
      } catch (error) {
        console.error("Failed to fetch cities:", error);
        // toast.error("Failed to load city options.");
      }
    };
    fetchCity();
  


    fetchDetails();
  }, [isPrefilled,isFetched]);
  useEffect(() => {
    // Update editableFields state based on isPrefilled value
    if (isPrefilled) {
      setEditableFields((prev) => ({
        ...prev,
        address: false,
        qualification: false,
        adhar_number: false,
        city: false,
        }));
    } else {
      setEditableFields((prev) => ({
        ...prev,
        address: true,
        qualification: true,
        adhar_number: true,
        city: true,
      }));
    }
    if (isFetched) {
      setEditableFields((prev) => ({
        ...prev,
        account_holder_name: false,
        account_number: false,
        upi: false,
        mobilenumber: false,
        branch_city: false,
        bank_name: false,
        ifsc_code: false,
      }));
    } else {
      setEditableFields((prev) => ({
        ...prev,
        account_holder_name: true,
        account_number: true,
        upi: true,
        mobilenumber: true,
        branch_city: true,
        bank_name: true,
        ifsc_code: true,
      }));
    }
  }, [isPrefilled,isFetched]); // Runs when isPrefilled is updated

  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  // Handle city change
  const handleCityChange = (selectedOption) => {
    setProfileDetails({ ...profileDetails, city_id: selectedOption.value });
  };

  const handleCityBankChange = (selectedOption) => {
    setBankDetails({ ...bankDetails, branch_city: selectedOption.value });
  };

  const handleInputChange = (e, formSetter) => {
    const { name, value } = e.target;
    formSetter((prev) => ({ ...prev,
      [name]: name === "city" || name === "branch_city" ? parseInt(value) : value}))
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0]; // Get the selected file
    if (file) {
      setPicture(file); // Update picture state
      setFileName(file.name); // Set the file name
    } else {
      setPicture(null);
      setFileName("");
    }
  };
  
  const goToNextStep = () => setCurrentStep((prev) => prev + 1);
  const goToPreviousStep = () => setCurrentStep((prev) => prev - 1);

  const handleProfileSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
   // Check for missing fields
  const isFormIncomplete = 
  !profileDetails.city_id ||
  !profileDetails.address ||
  !profileDetails.adhar_number ||
  !profileDetails.qualification ||
  !picture; // Ensure adhar_card (picture) is provided

if (isFormIncomplete) {
  toast.warning("Please fill all the required fields");
  setLoading(false)
  return;
}
    try {
      const formData = new FormData();
      Object.entries(profileDetails).forEach(([key, value]) => {
        formData.append(key, value);
      });

      // Append the picture (if it exists) like you did in handleSubmit
    if (picture) {
      formData.append('adhar_card', picture); // Add file field (picture)
    }

      const response = await axios.post(
       `${Base_URL}/certified-partner`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Profile details saved successfully:", response.data);
     toast.success('Details submitted successfully.')
     setIsPrefilled(true);
    } catch (error) {
      console.error("Error submitting profile details:", error);
      toast.error('Failed to submit.')
      setLoading(false)
    }finally {
      setLoading(false); // Reset loading state after submission
    }
  };


  const handleBankSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
     // Destructure the bank details for easy access
  const { account_holder_name, bank_name, account_number, ifsc_code,branch_city ,upi} = bankDetails;

  // Validation Logic
  if (upi) {
    // Ensure UPI ID is not blank or invalid
    if (upi.trim() === "") {
      toast.error("UPI ID cannot be blank.");
      setLoading(false)
      return; // Stop submission
    }
  } else {
    // If UPI ID is not filled, ensure all other details are complete
    if (!account_holder_name || !bank_name || !account_number || !ifsc_code || !branch_city ) {
      toast.warning("Please fill all required fields.");
       setLoading(false)
      return; // Stop submission
    }
  }
    try {
      const response = await axios.post(
        `${Base_URL}/create/bank_account`,
        bankDetails, // Pass the body (bankDetails) as the second argument
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Ensure "Authorization" is properly spelled
          },
        }
      )
    toast.success("Bank details saved successfully!");
    setIsFetched(true);
      // setTimeout(() => {
      //   navigate("/certified-partner-dashboard"); // Update with your actual dashboard route
      // }, 3000);
    } catch (error) {
      console.error("Error submitting bank details:", error.response?.data || error.message);
      toast.error(error.response?.data?.message || "Failed to save bank details. Please try again.")
      setLoading(false)
    }finally {
      setLoading(false); // Reset loading state after submission
    }
  };
  const handleEditClick = (fieldName) => {
    setEditableFields((prevFields) => ({
      ...prevFields,
      [fieldName]: true,
    }));
  };

  const handleProfileUpdate = async (e) => {
    e.preventDefault();
    setLoading(true)
    console.log("clicked update details");
    try {
      
  
      // Append the picture (if it exists) like you did in handleSubmit
      // if (picture) {
      //   formData.append("adhar_card", picture); // Add file field (picture)
      // }
  
      const response = await axios.put(
        `${Base_URL}/update/certified_partner/${profileId}`,profileDetails,
        {
          headers: {
            Authorization: `Bearer ${token}`,
           "Content-Type": "application/json",
          },
        }
      );
  
      console.log("Profile updated successfully:", response.data);
        toast.success("Updated successfully!");
    } catch (error) {
      console.error("Error updating profile details:", error);
      toast.error('Failed to update')
      setLoading(false)
    }finally {
      setLoading(false); // Reset loading state after submission
    }
  };

  const handleBankUpdate = async (e) => {
    e.preventDefault();
    setLoading(true)
  
    try {
      const response = await axios.put(
        `${Base_URL}/update/bank_account/${bankId}`,
        bankDetails,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
        toast.success("Bank details updated successfully!");
    } catch (error) {
      console.error("Error updating bank details:", error.response?.data || error.message);
      toast.error(
        error.response?.data?.message || "Failed to update bank details. Please try again."
      );
      setLoading(false)
    } finally{
      setLoading(false)
    }
  };
  
  

  return (
    <div className="bg-white font-sans-serif  min-h-screen flex flex-col items-center mt-6 md:mt-0 p-1 md:p-6">
       <ToastContainer />
         {showModal ? (
        <></>
      ) : (
      <div className="w-full mx-auto   border rounded-lg shadow-lg">
        {/* Navigation Arrows */}
        {currentStep > 1 && (
  <button
    className="flex items-center p-4 text-cmsgreen font-medium mb-1"
    onClick={goToPreviousStep}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="w-5 h-5 mr-2"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <polyline points="15 18 9 12 15 6" />
    </svg>
     Back
  </button>
)}


        {/* Step 1: Profile Details */}
        {currentStep === 1 && (
          <>
            {isPrefilled ? (
        <p className="text-center w-full font-bold text-cmsgreen text-xl py-2 mx-0 mt-4">
          Your profile is complete.
        </p>
      ) : (
        <p className="text-center w-full font-bold text-textGray text-xl py-2 mx-0 mt-4">
          Complete Your Profile to add Students.
        </p>
      )}

            <div className="p-6">
            <form onSubmit={isPrefilled ? handleProfileUpdate : handleProfileSubmit}>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">

              <div className="relative">
        <label htmlFor="city" className="block mb-2 font-medium">
          City
        </label>
        {editableFields.city ? (
          <Select
            options={cities}
            value={cities.find((option) => option.value === profileDetails.city_id)}
            onChange={handleCityChange}
            placeholder="Select City"
            className="basic-single border-slate-300 rounded-lg focus:border-cmsgreen focus:ring-cmsgreen focus:outline-none"
            styles={{
              control: (provided) => ({
                ...provided,
                padding: "0.1rem",
                borderColor: "#cbd5e1",
                borderRadius: "0.5rem",
                outline: "none",
                boxShadow: "none",
                "&:hover": { borderColor: "#41B923" },
              }),
              placeholder: (provided) => ({
                ...provided,
                color: "#a1a1aa",
              }),
            }}
          />
        ) : (
          <div className="relative">
            <input
              type="text"
              value={cityName || "Select City"}
              placeholder="Select City"
              className="w-full p-2 pr-10 border-slate-300 rounded-lg focus:border-cmsgreen focus:ring-cmsgreen focus:outline-none"
              disabled
            />
            <MdEdit
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-cmsgreen cursor-pointer text-lg"
              onClick={() => handleEditClick("city")}
            />
          </div>
        )}
      </div>
              <div className="relative">
                <label className="block mb-2 font-medium">Complete Address</label>
                <div className="relative">
                <input
                  type="text"
                  name="address"
                  value={profileDetails.address}
                  onChange={(e) => handleInputChange(e, setProfileDetails)}
                  placeholder="Enter your address"
                  className="w-full p-2 border-slate-300 rounded-lg focus:border-cmsgreen focus:ring-cmsgreen focus:outline-none"
                  disabled={!editableFields.address}
                />
                  {!editableFields.address && (
            <MdEdit
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-cmsgreen cursor-pointer text-lg"
              onClick={() => handleEditClick("address")}
            />
          )}
                </div>
               
              </div>
              <div className="relative">
  <label className="block mb-2 font-medium">Qualification</label>
  <div className="relative">
    <input
      type="text"
      name="qualification"
      value={profileDetails.qualification}
      onChange={(e) => handleInputChange(e, setProfileDetails)}
      placeholder="Enter your qualification"
      className="w-full p-2 pr-10 border-slate-300 rounded-lg focus:border-cmsgreen focus:ring-cmsgreen focus:outline-none"
      disabled={!editableFields.qualification}
    />
   {!editableFields.qualification && (
            <MdEdit
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-cmsgreen cursor-pointer text-lg"
              onClick={() => handleEditClick("qualification")}
            />
          )}
  </div>
</div>
              <div className="relative">
                <label className="block mb-2 font-medium">Aadhaar Number</label>
                <div className="relative">
                <input
                  type="text"
                  name="adhar_number"
                  value={profileDetails.adhar_number}
                  onChange={(e) => handleInputChange(e, setProfileDetails)}
                  placeholder="Enter your Aadhaar number"
                  className="w-full p-2 border-slate-300 rounded-lg focus:border-cmsgreen focus:ring-cmsgreen focus:outline-none"
                  disabled={!editableFields.adhar_number}
                />
                  {!editableFields.adhar_number && (
            <MdEdit
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-cmsgreen cursor-pointer text-lg"
              onClick={() => handleEditClick("adhar_number")}
            />
          )}
                </div>
                
              </div>

<div>
  <label className="block mb-2 font-medium">Aadhaar Card</label>
  <div className="relative">
    {/* Hidden file input */}
    <input
      type="file"
      id="aadhaarFile"
      // name="adhar_card"
      className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
      onChange={(e) => handleFileChange(e, setProfileDetails)}
      disabled={isPrefilled}
    />
    {/* Label to trigger the file input */}
    <label
      htmlFor="aadhaarFile"
      className="w-full h-[40px] p-2 border border-slate-300 rounded-lg flex items-center justify-between text-gray-500 cursor-pointer"
    >
    {isPrefilled? pictureName: "Upload Aadhaar"}
      <FiUpload className="text-cmsgreen" />
    </label>
    {fileName && (
        <p style={{ marginTop: "8px", fontSize: "14px", color: "green" }}>
          {fileName} uploaded successfully!
        </p>
      )}
  </div>
</div>

         
</div>
{isPrefilled ? (
        <div className="flex gap-4">
          <button
            className="bg-cmsgreen hover:bg-hovergreen text-white px-6 py-2 mt-6 rounded-lg"
            type="submit"
          >
           {loading ? (
                <div className="flex justify-center items-center">
                  <svg
                    className="animate-spin h-5 w-5 text-white mr-3"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                    ></path>
                  </svg>
                  Updating
                </div>
              ) : (
                'Update'
              )}
          </button>
          <button
            className="bg-cmsgreen hover:bg-hovergreen text-white px-6 py-2 mt-6 rounded-lg"
            onClick={goToNextStep}
          >
            Next
          </button>
        </div>
      ) : (
        <div className="flex gap-4">
        <button
          className="bg-cmsgreen hover:bg-hovergreen text-white px-4 py-2 mt-6 rounded-lg"
          type="submit"
        >
        {loading ? (
                <div className="flex justify-center items-center">
                  <svg
                    className="animate-spin h-5 w-5 text-white mr-3"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                    ></path>
                  </svg>
                  Saving
                </div>
              ) : (
                'Save'
              )}
        </button>
        <button
            className="bg-cmsgreen hover:bg-hovergreen text-white px-6 py-2 mt-6 rounded-lg"
            onClick={goToNextStep}
          >
            Next
          </button>
        </div>
      )}
            </form>
           
            </div>
         
           
          </>
        )}

        {/* Step 2: Bank Details */}
        {currentStep === 2 && (
          <>
            <h2 className="text-center text-xl  text-textGray font-bold mb-2">
              Bank Account Details
            </h2>
            <div className="p-6">
            <form onSubmit={isFetched ? handleBankUpdate : handleBankSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">

              <div className="relative">
                <label className="block mb-2 font-medium">Account Holder Name</label>
                <div className="relative">
                <input
                  type="text"
                  name="account_holder_name"
                  value={bankDetails.account_holder_name}
                  onChange={(e) => handleInputChange(e, setBankDetails)}
                  placeholder="Enter account holder name"
                  className="w-full p-2 border-slate-300 rounded-lg focus:border-cmsgreen focus:ring-cmsgreen focus:outline-none"
                  
                />
                 {!editableFields.account_holder_name && (
            <MdEdit
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-cmsgreen cursor-pointer text-lg"
              onClick={() => handleEditClick("account_holder_name")}
            />
          )}
          </div>
              </div>
              <div className="relative">
                <label className="block mb-2 font-medium">Bank Name</label>
                <div className="relative">
                <input
                  type="text"
                  name="bank_name"
                  value={bankDetails.bank_name}
                  onChange={(e) => handleInputChange(e, setBankDetails)}
                  placeholder="Enter bank name"
                  className="w-full p-2 border-slate-300 rounded-lg focus:border-cmsgreen focus:ring-cmsgreen focus:outline-none"
                  
                />
                  {!editableFields.bank_name && (
            <MdEdit
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-cmsgreen cursor-pointer text-lg"
              onClick={() => handleEditClick("bank_name")}
            />
          )}
          </div>
                
              </div>
              <div className="relative">
                <label className="block mb-2 font-medium">Account Number</label>
                <div className="relative">
                <input
                  type="text"
                  name="account_number"
                  value={bankDetails.account_number}
                  onChange={(e) => handleInputChange(e, setBankDetails)}
                  placeholder="Enter account number"
                  className="w-full p-2 border-slate-300 rounded-lg focus:border-cmsgreen focus:ring-cmsgreen focus:outline-none"
                  
                />
                 {!editableFields.account_number && (
            <MdEdit
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-cmsgreen cursor-pointer text-lg"
              onClick={() => handleEditClick("account_number")}
            />
          )}
          </div>
              </div>
              <div className="relative">
                <label className="block mb-2 font-medium">Mobile Number</label>
                <div className="relative">
                <input
                  type="text"
                  name="mobilenumber"
                  value={bankDetails.mobilenumber}
                  onChange={(e) => handleInputChange(e, setBankDetails)}
                  placeholder="Enter mobile number"
                  className="w-full p-2 border-slate-300 rounded-lg focus:border-cmsgreen focus:ring-cmsgreen focus:outline-none"
                  
                />
                 {!editableFields.mobilenumber && (
            <MdEdit
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-cmsgreen cursor-pointer text-lg"
              onClick={() => handleEditClick("mobilenumber")}
            />
          )}
                </div>
              </div>
              <div className="relative">
                <label className="block mb-2 font-medium">IFSC Code</label>
                <div className="relative">
                <input
                  type="text"
                  name="ifsc_code"
                  value={bankDetails.ifsc_code}
                  onChange={(e) => handleInputChange(e, setBankDetails)}
                  placeholder="Enter IFSC code"
                  className="w-full p-2 border-slate-300 rounded-lg focus:border-cmsgreen focus:ring-cmsgreen focus:outline-none"
                />
                 {!editableFields.ifsc_code && (
            <MdEdit
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-cmsgreen cursor-pointer text-lg"
              onClick={() => handleEditClick("ifsc_code")}
            />
          )}
          </div>
              </div>
              <div className="relative ">
  <label htmlFor="branch_city" className="block mb-2 font-medium">
    Branch City
  </label>
  {editableFields.branch_city ? (
    <Select
      options={cities}
      value={cities.find((option) => option.value === bankDetails.branch_city)}
      onChange={handleCityBankChange}
      placeholder="Select City"
      className="basic-single border-slate-300 rounded-lg focus:border-cmsgreen focus:ring-cmsgreen focus:outline-none"
      styles={{
        control: (provided) => ({
          ...provided,
          padding: "0.1rem",
          borderColor: "#cbd5e1",
          borderRadius: "0.5rem",
          outline: "none",
          boxShadow: "none",
          "&:hover": { borderColor: "#41B923" },
        }),
        placeholder: (provided) => ({
          ...provided,
          color: "#a1a1aa",
        }),
      }}
    />
  ) : (
    <div className="relative">
      <input
        type="text"
        value={branchcityName || "Select City"}
        placeholder="Select City"
        className="w-full p-2 pr-10 border-slate-300 rounded-lg focus:border-cmsgreen focus:ring-cmsgreen focus:outline-none"
        disabled
      />
      <MdEdit
        className="absolute right-3 top-1/2 transform -translate-y-1/2 text-cmsgreen cursor-pointer text-lg"
        onClick={() => handleEditClick("branch_city")}
      />
    </div>
  )}
</div>

              <div className="relative"> 
                <label className="block mb-2 font-medium">UPI</label>
                <div className="relative">
                <input
                  type="text"
                  name="upi"
                  value={bankDetails.upi}
                  onChange={(e) => handleInputChange(e, setBankDetails)}
                  placeholder="Enter UPI ID"
                  className="w-full p-2 border-slate-300 rounded-lg focus:border-cmsgreen focus:ring-cmsgreen focus:outline-none"
                />
                 {!editableFields.upi && (
            <MdEdit
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-cmsgreen cursor-pointer text-lg"
              onClick={() => handleEditClick("upi")}
            />
          )}
          </div>
              </div>
</div>
             {/* Conditionally hide or disable the button */}
             {isFetched ? (
  <button
    className="bg-cmsgreen hover:bg-hovergreen text-white px-6 py-2 mt-6 rounded-lg"
    type="submit"
   
  >
    {loading ? (
                <div className="flex justify-center items-center">
                  <svg
                    className="animate-spin h-5 w-5 text-white mr-3"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                    ></path>
                  </svg>
                  Updating
                </div>
              ) : (
                'Update'
              )}
  </button>
) : (
  <button
    className="bg-cmsgreen hover:bg-hovergreen text-white px-4 py-2 mt-6 rounded-lg"
    type="submit"
  
  >
    {loading ? (
                <div className="flex justify-center items-center">
                  <svg
                    className="animate-spin h-5 w-5 text-white mr-3"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                    ></path>
                  </svg>
                  Saving
                </div>
              ) : (
                'Save'
              )}
  </button>
)}
            </form>
           
            </div>
          
          </>
        )}
      </div>
      )}
    </div>
  );
}

export default PartnerPersonalDetails;
